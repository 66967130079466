import React from "react";
import {Trans, useTranslation, I18nextContext, Link} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import {createMarkup} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";

import CarouselWrapper from '../../../components/carousel/CarouselWrapper';
import Project01 from './images/project/01-2-project-01a.jpg';
import Project02 from './images/project/01-2-project-02a.jpg';
import Project04 from './images/project/01-2-project-04a.jpg';
import Project05 from './images/project/01-2-project-05a.jpg';
import Project06 from './images/project/01-2-project-06a.jpg';
import Project07 from './images/project/01-2-project-07a.jpg';
import Project09 from './images/project/01-2-project-09a.jpg';
import Project10 from './images/project/01-2-project-10a.jpg';
import Project11 from './images/project/amazon-spain.jpg';
import Project12 from './images/project/01-2-project-12a.jpg';
import Project13 from './images/project/01-2-project-13a.jpg';
import Project14 from './images/project/01-2-project-14a.jpg';

import pedestals from './images/applications/pedestals.jpg';
import personalStorage from './images/applications/personal-storage.jpg';
import credenzas from './images/applications/credenzas.jpg';
import towers from './images/applications/towers.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-workspace.jpg";
import CustomLocalizedLink from "../../../components/locales/custom-localized-link";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";

const IndexPage = () => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const projectImages = [
    {
      original: Project06,
      originalAlt: 'Tech Giant Office',
      thumbnailAlt: 'Tech Giant Office thumbnail',
      thumbnail: Project06,
      thumbnailLabel: 'Tech Giant',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project09,
      originalAlt: 'Employee lockers at Nike',
      thumbnailAlt: 'Employee lockers at Nike thumbnail',
      thumbnail: Project09,
      thumbnailLabel: 'Nike',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project01,
      originalAlt: 'Versa Electronic Locks at Amazon Headquarters',
      thumbnailAlt: 'Versa Electronic Locks at Amazon Headquarters thumbnail',
      thumbnail: Project01,
      thumbnailLabel: 'Amazon',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project02,
      originalAlt: 'Pedestals Secured with Versa Mini at Genentech Workspace',
      thumbnailAlt: 'Pedestals Secured with Versa Mini at Genentech Workspace thumbnail',
      thumbnail: Project02,
      thumbnailLabel: 'Genentech',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project04,
      originalAlt: 'Digilock Employee lockers at Accenture',
      thumbnailAlt: 'Digilock Employee lockers at Accenture thumbnail',
      thumbnail: Project04,
      thumbnailLabel: 'Accenture',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project05,
      originalAlt: 'Jones Lang Lasalle employee lockers',
      thumbnailAlt: 'Jones Lang Lasalle employee lockers thumbnail',
      thumbnail: Project05,
      thumbnailLabel: 'Jones Lang LaSalle',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project07,
      originalAlt: 'Electronic Lockers Installed with Custom Cue by Digilock Locks at Accenture',
      thumbnailAlt: 'Electronic Lockers Installed with Custom Cue by Digilock Locks at Accenture thumbnail',
      thumbnail: Project07,
      thumbnailLabel: 'Accenture',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project10,
      originalAlt: 'Employee lockers at IBM',
      thumbnailAlt: 'Employee lockers at IBM thumbnail',
      thumbnail: Project10,
      thumbnailLabel: 'IBM',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project11,
      originalAlt: 'Fannie Mae employee lockers',
      thumbnailAlt: 'Fannie Mae employee lockers thumbnail',
      thumbnail: Project11,
      thumbnailLabel: 'Fannie Mae',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project12,
      originalAlt: 'Digilock Mastercard employee lockers',
      thumbnailAlt: 'Digilock Mastercard employee lockers thumbnail',
      thumbnail: Project12,
      thumbnailLabel: 'Mastercard',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project13,
      originalAlt: 'Keypad employee lockers at Amgen',
      thumbnailAlt: 'Keypad employee lockers at Amgen thumbnail',
      thumbnail: Project13,
      thumbnailLabel: 'Amgen',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project14,
      originalAlt: 'Astellas Pharma Employee Lockers Installed with Digilock Cue with Pull Handle',
      thumbnailAlt: 'Astellas Pharma Employee Lockers Installed with Digilock Cue with Pull Handle thumbnail',
      thumbnail: Project14,
      thumbnailLabel: 'Astellas Pharma',
      productLink: returnLocaleURL(sl, '/products/')
    }
  ];
  const projectAsides = [
    '<span>united states</span><br>' +
    '<h2>Big Tech</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Aspire RFID</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Nike</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Sola Keypad</a></p>',

    '<span>italy</span><br>' +
    '<h2>Amazon</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa RFID</a></p>',

    '<span>united states</span><br>' +
    '<h2>Genentech</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa Mini</a></p>',

    '<span>united states</span><br>' +
    '<h2>Accenture</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Jones Lang Lasalle</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Accenture</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>IBM</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G RFID</a></p>',

    '<span>spain</span><br>' +
    '<h2>Amazon</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa RFID</a></p>',

    '<span>united arab emirates</span><br>' +
    '<h2>Mastercard</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Aspire RFID</a></p>',

    '<span>united states</span><br>' +
    '<h2>Amgen</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock Versa Keypad</a></p>',

    '<span>the Netherlands</span><br>' +
    '<h2>Astellas Pharma</h2>' +
    '<p><a href="'+returnLocaleURL(sl, '/products/')+'">Digilock 4G RFID</a></p>'
  ];
  const ProjectAside = _ => <aside className={'dynamic-aside'}
                                   dangerouslySetInnerHTML={createMarkup(projectAsides[0])} />;
  const solutions = [
    {
      img: personalStorage,
      label: 'Drawer Locks',
      alt: 'personal_storage',
      bg: 'light'
    },
    {
      img: pedestals,
      label: 'Pedestal Locks',
      alt: 'pedestals',
      bg: 'dark'
    },
    {
      img: credenzas,
      label: 'File Cabinet Locks',
      alt: 'file cabinet locks',
      bg: 'light'
    },
    {
      img: towers,
      label: 'Cabinet Locks',
      alt: 'cabinet locks',
      bg: 'light'
    }

  ];

  return (
      <Layout>
        <Seo
            title={t('solutions_workspace')}
            description={t('des_solutions_workspace')}
        />
        <div className="solutions workspace">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src={'../../../images/industries/hero-workspace.jpg'}
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="workspace"
            />
            <h1>
              <Trans>
                workspace
              </Trans>
              <span>
                <Trans>workspace_hero_subhead</Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>workspace_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>workspace_subhero_list_l1</Trans></li>
                  <li><Trans>workspace_subhero_list_l2</Trans></li>
                  <li><Trans>workspace_subhero_list_l3</Trans></li>
                  <li><Trans>workspace_subhero_list_l4</Trans></li>
                  <li><Trans>workspace_subhero_list_l5</Trans></li>
                  <li><Trans>workspace_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/workspace-sub-hero.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="workspace office"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup showPivot={true} showCurve={true} showAspire={true} showVersa={true} showOrbit={true} showCTA={true} label={'workspace_lineup_label'}   />
          <section className="industry-carousel">
            <div className="container">
              <h2><Trans>workspace_carousel</Trans></h2>
              <CarouselWrapper
                  items={projectImages}
                  asides={projectAsides}
                  projectAside={<ProjectAside />}
                  showThumbnails={true}
                  showBullets={true}
                  dynamicAside={true}
                  allowImageClick={false}
              />
            </div>
          </section>
          <br/>
          <section className="customer-video healthcare">
              <CustomerVideo
                  poster={posterSolution}
                  title={'workspace_customer_video_title'}
                  copy={'workspace_customer_video_copy'}
                  vidURL={'https://cdn.mediavalet.com/usva/digilock/-lFm5r1GM060geeLH4_GPQ/Hym5jRhzUUqpuXc3d6YSIg/Widescreen720p/workspace.mp4'}
              />
          </section>

          <section className="industry-uses">
            <div className="container">
              <h2><Trans>workspace_popular_uses</Trans></h2>
              <Solution items={solutions}/>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
